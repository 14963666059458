const DEV = "http://192.168.1.235:3001";
//const DEV = "http://radnor.ltem.net:3001";
let PROD = "https://www.monger.tech/api";
const ENV = PROD;

let config = {
  api: ENV,
  devApi: "http://192.168.1.235:3001",
  primaryColor: "#428BCA",
  cigAPI: "https://www.cigintra.net/cigapi",
  colors: {
    lightgreen: "#6CF492",
    blue: "#428bca",
    green: "#44ce6f",
    purple: "#c679e3",
    red: "#E03616",
    darkblue: "#428BCA", // cig
    background: "#F8F8F8",
  },
  PGTimeFormat: "YYYY-MM-DDTHH:mm:ss",
};

export default config;
